export default function ClassNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const isEmptyOrNull = (val) => {
  return val === "" || val === null || val === undefined;
};

export const isDefined = (field) => {
    if(field === 0) return true;
    return !!field;
};

export const phoneNumberFormatter =(phoneNumber) => {
  return phoneNumber.replace(/[&/\\#,+( )$~%.'":*?<>{}-]/g, "")
}