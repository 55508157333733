import { useState, useEffect } from "react";
import ClassNames from "Helpers/Common";
import { EMAIL_INVALID } from "../ErrorMsgs";
import { LOGIN_VIEW } from "Constants/Views";
import { useLoginContext } from "Context/Login";
import { ApiError } from "Components/Common/ApiError";
import { Button } from "Components/Common/Button/Button";
import { ReactComponent as EmailIcon } from "Assets/Mail.svg";
import { ForgotPasswordReq } from "Services/API/Login.service";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import { InputField } from "Components/Common/InputField/InputField";
import "Colors.css";

const OTP_VIEW = "otpScreen";

export const EmailView = ({ setCurrentView, setUserCredential }) => {
  const { setActiveView } = useLoginContext();
  const [email, setEmail] = useState("");
  const [apiError, setApiError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  useEffect(() => {
    if (email && email.includes("@") && email.includes(".com")) {
      setIsDisabled(false);
    }
  }, [email]);

  const handleSubmit = () => {
    setIsBtnLoading(true);
    ForgotPasswordReq({ email })
      .then(({ data: { result } }) => {
        if (result?.includes("inbox")) {
          setUserCredential({
            email: email,
            otp: "",
            newPassword: "",
            confirmPassword: "",
          });
          setCurrentView(OTP_VIEW);
          setIsBtnLoading(false);
        }
      })
      .catch((err) => {
        console.error("[ForgotPasswordReq][API_ERROR] - ", err);
        setApiError(err?.response?.data?.errors);
        setIsBtnLoading(false);
        setIsDisabled(true)
      });
  };

  return (
    <>
    
      <div
        onClick={() => setActiveView(LOGIN_VIEW)}
        className="flex items-center mb-12"
      >
        <BackIcon className="h-6 w-6 text-blue-chill cursor-pointer" />
        <span className="ml-2 text-blue-chill text-base cursor-pointer">
          {" "}
          Login
        </span>
      </div>
      <span className="sapphire mt-6 text-3xl font-extrabold">
        Forgot password?
      </span>
      <br />
      <span className="text-blue-chill text-left font-medium w-72">
        Enter the email associated with your account
      </span>
      <div className="pt-4">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}
      </div>

      <div className="space-y-6 mt-8">
        <InputField
          label="Email"
          id="email"
          type="email"
          isRequired={true}
          onChange={(fieldValue) => setEmail(fieldValue.trim())}
          onBlur={(fieldValue, setInputFieldError) => {
            if (!(fieldValue.includes("@") && fieldValue.includes(".com"))) {
              setInputFieldError(EMAIL_INVALID);
              setIsDisabled(true);
            } else if (fieldValue) {
              setEmail(fieldValue.trim());
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && email) {
              handleSubmit();
            }
          }}
        >
          <EmailIcon className="w-6 h-6 light_grayish_navy pointer-events-none" />
        </InputField>

        <Button
          btnText="Continue"
          isLoading={isBtnLoading}
          className={ClassNames("w-full py-2", !isDisabled && "text-white")}
          isDisabled={isDisabled}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};
