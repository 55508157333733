import React, { useState } from "react";
import SignIn from "Components/Signin/Login";
import { useLoginContext } from "Context/Login";
import Verification from "Components/Signin/Verification";
import ForgotPassword from "Components/Signin/ForgotPassword";
import LoginLayout from "Layouts/SigninLayout/SignInLayout";
import { VERIFICATION_VIEW, FORGOT_PASSWORD_VIEW } from "Constants/Views";

export default function SignInContainer() {
  return (
    <div>
      <LoginLayout>
        <ActiveContainer />
      </LoginLayout>
    </div>
  );
}

const ActiveContainer = () => {
  const { activeView } = useLoginContext();

  switch (activeView) {
    case VERIFICATION_VIEW:
      return <Verification />;
    case FORGOT_PASSWORD_VIEW:
      return <ForgotPassword />;

    default:
      return <SignIn />;
  }
};
