import moment from "moment"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (date, format) => {
  if ((date, format)) {
    return moment(date).format(format);
  }
  return ;
};

export const formatDate2 = (date, format = 'MM/DD/YY h:mma', tz = 'America/Los_Angeles', defaultValue = '-') => {
  if (dayjs(date).isValid()) {
    return dayjs(date).tz(tz).format(format)
  }
  return defaultValue
}