import { useState } from "react";
import { EmailView } from "./ForgotPasswordFlow/EmailView";
import OTPView from "./ForgotPasswordFlow/OTPView";
import { ResetPassword } from "./ForgotPasswordFlow/ResetPassowrdView";

const EMAIL_VIEW = "emailScreen";
const OTP_VIEW = "otpScreen";
const RESET_PASSWORD_VIEW = "resetPasswordScreen";

export default function ForgotPassword() {
  const [userCredentials, setUserCredential] = useState({
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [currentView, setCurrentView] = useState(EMAIL_VIEW);
  return (
    <>
      {currentView === EMAIL_VIEW ? (
        <EmailView
          setCurrentView={setCurrentView}
          setUserCredential={setUserCredential}
        />
      ) : currentView === OTP_VIEW ? (
        <OTPView
          userCredentials={userCredentials}
          setCurrentView={setCurrentView}
          setUserCredential={setUserCredential}
        />
      ) : (
        <ResetPassword
          userCredentials={userCredentials}
          setCurrentView={setCurrentView}
        />
      )}
    </>
  );
}
