import axios from "axios";
import { API_BASE } from "Config/Secrets";
import { LOGIN } from "Constants/LocalStorage";
import { RefreshTokenReq } from "Services/API/Login.service";
const customAxios = axios.create({
  baseURL: API_BASE,
  timeout:50000,
});

const requestHandler = (request) => {
  const { idToken } = JSON.parse(localStorage.getItem(LOGIN));

  if (idToken) {
    request.headers.Authorization = `Bearer ${idToken}`;

    return request;
  }
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let code = parseInt(error.message.replace(/\D/g, ""));
    const { refreshToken } = JSON.parse(localStorage.getItem(LOGIN));
    const email = JSON.parse(localStorage.getItem("USER_EMAIL"));
    if (code === 401) {
      const { idToken } = JSON.parse(localStorage.getItem(LOGIN));

      if (!idToken) {
        localStorage.setItem(
          LOGIN,
          JSON.stringify({
            login: "",
            idToken: "",
            token: "",
            tokenExpiry: "",
            refreshToken: "",
          })
        );
        window.location = "/login";
        return;
      }

      RefreshTokenReq({ email, refreshToken: refreshToken })
        .then(({ auth }) => {
          localStorage.setItem(
            LOGIN,
            JSON.stringify({
              idToken: auth.IdToken,
              accessToken: auth.AccessToken,
              tokenExpiry: auth.ExpiresIn,
              refreshToken,
            })
          );
          window.location.reload();
        })
        .catch((err) => {
          localStorage.setItem(
            LOGIN,
            JSON.stringify({
              login: "",
              idToken: "",
              token: "",
              tokenExpiry: "",
              refreshToken: "",
            })
          );
          console.error('[RefreshTokenReq][API_ERROR] =>',err);
          window.location = "/login";
        });
      // return axios.request(error); // repeat last API call
    }
    return Promise.reject(error);
  }
);

export default customAxios;
