import { useState, useEffect } from "react";
import { Button } from "Components/Common/Button/Button";
import { ReactComponent as PasswordIcon } from "Assets/Password.svg";
import { InputField } from "Components/Common/InputField/InputField";
import { isPasswordValid } from "Helpers/Login";
import { PASSWORD_ERROR } from "../ErrorMsgs";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import { ChangePassword } from "Services/API/Login.service";
import { useLoginContext } from "Context/Login";
import { ApiError } from "Components/Common/ApiError";
import { LOGIN_VIEW } from "Constants/Views";
import "Colors.css";

const OTP_VIEW = "otpScreen";

export function ResetPassword({ userCredentials, setCurrentView }) {
  const { email, otp } = userCredentials;
  const { setActiveView } = useLoginContext();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (newPassword && confirmNewPassword) {
      setIsDisabled(!(newPassword === confirmNewPassword));
    }
  }, [newPassword, confirmNewPassword]);

  const handleSubmit = () => {
    setIsLoading(true);
    ChangePassword({
      email: email,
      password: newPassword,
      authCode: otp,
    })
      .then((res) => {
        setActiveView(LOGIN_VIEW);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("[ChangePassword][API_ERROR] =>", err);
        setApiError(err?.response?.data?.errors);
        setIsDisabled(true);
      });
  };

  return (
    <div className="ml-5 w-4/5">
      <div onClick={() => setCurrentView(OTP_VIEW)} className="flex mb-12">
        <BackIcon className="h-6 w-6 text-blue-chill cursor-pointer" />
        <span className="ml-2 text-blue-chill text-base cursor-pointer">
          {" "}
          Login
        </span>
      </div>
      <span className="sapphire mt-6 text-3xl font-extrabold">
        Reset Password
      </span>
      <br />
      <span className="text-blue-chill text-left font-medium">
        Make sure your new password is different from your previous password
      </span>
      {apiError && <ApiError error={apiError} setApiError={setApiError} />}

      <div className="lg:space-y-2 2xl:space-y-4 mt-8">
        <InputField
          id="new-password"
          type="password"
          label="New Password"
          isRequired={true}
          onChange={(fieldValue) => setNewPassword(fieldValue)}
          onBlur={(fieldValue, setInputFieldError) => {
            if (isPasswordValid(fieldValue)) {
              setNewPassword(fieldValue);
            } else {
              setInputFieldError(PASSWORD_ERROR);
              setIsDisabled(true);
            }
          }}
        >
          <PasswordIcon
            className="w-6 h-6 light_grayish_navy pointer-events-none"
            onIconClick={() => alert("Password")}
          />
        </InputField>
        <InputField
          id="confirm-password"
          type="password"
          label="Confirm Password"
          isRequired={true}
          onChange={(fieldValue) => setConfirmNewPassword(fieldValue)}
          onBlur={(fieldValue, setInputFieldError) => {
            if (fieldValue === newPassword) {
              setConfirmNewPassword(fieldValue);
            } else {
              setInputFieldError("Password Mismatch !");
              setIsDisabled(true);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && newPassword === confirmNewPassword) {
              handleSubmit();
            }
          }}
        >
          <PasswordIcon className="w-6 h-6 light_grayish_navy pointer-events-none" />
        </InputField>
        <Button
          className="w-full py-2"
          btnText="Continue"
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
