import { useState, useEffect } from "react";
import { Button } from "Components/Common/Button/Button";
import { ForgotPasswordReq } from "Services/API/Login.service";
import { ReactComponent as BackIcon } from "Assets/BackArrow.svg";
import { ApiError } from "Components/Common/ApiError";
import ClassNames from "Helpers/Common";
import OtpInput from "react-otp-input";
import "../Verification.css";
import "Colors.css";
import { ErrorPopUp } from "Components/Common/PopUp/PopUp";

const TOTAL_VERIFICATION_CHARS = 6;
const EMAIL_VIEW = "emailScreen";
const RESET_PASSWORD_VIEW = "resetPasswordScreen";

export default function OTPView({
  userCredentials,
  setCurrentView,
  setUserCredential,
}) {
  const { email } = userCredentials;
  const [msg, setMsg] = useState("");
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [codeVerificationChars, setCodeVerificationChars] = useState("");

  useEffect(() => {
    setIsDisabled(!(codeVerificationChars.length === TOTAL_VERIFICATION_CHARS));
  }, [codeVerificationChars]);

  const handleResend = () => {
    if (email) {
      setIsLoading(true);
      ForgotPasswordReq({ email })
        .then(({ data: { result } }) => {
          setMsg(result);
          console.log("[ForgotPasswordReq][API_RESPONSE] - ", result);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsDisabled(true);
          console.error("[ForgotPasswordReq][API_ERROR] - ", err);
          setApiError(err?.response?.data?.errors);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div
        onClick={() => setCurrentView(EMAIL_VIEW)}
        className="flex mb-12 items-center gap-3"
      >
        <BackIcon className="text-blue-chill cursor-pointer" />
        <span className="text-blue-chill text-base cursor-pointer"> Back</span>
      </div>
      <span className="sapphire mt-6 text-3xl font-extrabold font-poppins">
        Verification
      </span>
      <br />
      <div className="text-blue-chill text-left font-medium font-poppins w-72">
        Please enter the code sent to your email
      </div>
      <div className="pt-4">
        {apiError && <ApiError error={apiError} setApiError={setApiError} />}
        {msg && (
          <ErrorPopUp
            msg={msg}
            setApiError={setMsg}
            onClick={() => setMsg("")}
          />
        )}
      </div>
      <div className="space-y-6 mt-8">
        <div
          className="flex flex-row justify-between gap-4 text-center Code_Boxs"
          onKeyPress={(e) => {
            if (e.key === "Enter" && codeVerificationChars?.length >= 5) {
              setUserCredential({
                email: email,
                otp: codeVerificationChars,
                newPassword: "",
                confirmPassword: "",
              });
              setCurrentView(RESET_PASSWORD_VIEW);
            }
          }}
        >
          <OtpInput
            value={codeVerificationChars}
            onChange={(otp) => {
              setCodeVerificationChars(otp);
            }}
            numInputs={6}
            autoFocus={true}
            placeholder="XXXXXX"
            inputStyle={{
              border: "1px solid #45969f",
              borderRadius: "8px",
              width: "60px",
              height: "54px",
              fontSize: "16px",
              color: "#000",
              fontWeight: "300",
              caretColor: "#9298A9",
              marginLeft: "10px",
            }}
            focusStyle={{
              border: "1px solid #9298A9",
              outline: "none",
            }}
          />
        </div>
        <div>
          <Button
            btnText="Verify"
            className={ClassNames("w-full py-2", !isDisabled && "text-white")}
            isDisabled={isDisabled}
            isLoading={false}
            onClick={() => {
              setUserCredential({
                email: email,
                otp: codeVerificationChars,
                newPassword: "",
                confirmPassword: "",
              });
              setCurrentView(RESET_PASSWORD_VIEW);
            }}
          />
          <div className="flex justify-between mt-3">
            <span>Code expires in </span>
            <span
              onClick={() => handleResend()}
              className={ClassNames(
                "text-blue-chill cursor-pointer",
                isLoading && "animate-pulse"
              )}
            >
              Resend code
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
