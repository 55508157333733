import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import ClassNames from "Helpers/Common";

export const ErrorPopUp = ({ msg, error, setApiError, onClick }) => {
  return (
    <>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={!!error || !!msg}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={ClassNames(
                "max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto border  overflow-hidden cursor-pointer",
                error ? "border-red-900" : "border-blue-chill"
              )}
            >
              <div className="p-2">
                <div className="flex items-start">
                  <div className="ml-2 w-0 flex-1 pt-0.5">
                    <p
                      className={ClassNames(
                        "flex items-center gap-2  text-lg font-medium animate-pulse",
                        error ? "text-red-900" : "text-blue-chill"
                      )}
                    >
                      {" "}
                      <ExclamationIcon
                        className={ClassNames(
                          "h-6 w-6",
                          error ? "text-red-900" : "text-blue-chill"
                        )}
                        aria-hidden="true"
                      />
                      {error ? Error : msg ? "Notice" : ""}
                    </p>
                    <p
                      className={ClassNames(
                        "mt-1 text-sm",
                        error ? "text-red-900" : "text-blue-chill"
                      )}
                    >
                      {msg || error}
                    </p>
                    <div className="mt-3 flex space-x-7">
                      <button
                        type="button"
                        onClick={() => {
                          setApiError?.("");
                          onClick?.();
                        }}
                        className={ClassNames(
                          "rounded-md text-sm font-medium focus:outline-none",
                          error
                            ? "text-red-800 hover:text-red-900"
                            : "text-blue-chill hover:text-blue-light"
                        )}
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className={ClassNames(
                        "rounded-md inline-flex focus:outline-none",
                        error
                          ? "text-red-800 hover:text-red-900"
                          : "text-blue-chill hover:text-blue-light"
                      )}
                      onClick={() => {
                        setApiError?.("");
                        onClick?.();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};
